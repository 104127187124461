
import PDESkeleton from '@/components/pde/PDESkeleton.vue'

export default {
  name: 'TravelLoadingScreen',

  components: {
    PDESkeleton,
  },

  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },

}
