
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'FullItineraryAccordion',
  components: {
    PDEIcon,
  },

  props: {
    expand: {
      type: Boolean,
      default: false,
    },
    alwaysExpanded: {
      type: Boolean,
      default: false,
    },
    editedOn: {
      type: String,
      default: '',
    },
    portalStatus: {
      type: String,
      default: null,
    },
    showDropdown: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['expanded'],
  data({ alwaysExpanded, expand }) {
    return {
      expanded: alwaysExpanded || expand,
      offsetHeight: null,
    }
  },

  computed: {
    heightStyle() {
      return !this.offsetHeight
        ? { } // needed to allow animation to work
        : {
            maxHeight: this.expanded ? this.offsetHeight : '0px',
          }
    },
    showButton() {
      return !this.alwaysExpanded && this.portalStatus !== 'Pending' && this.showDropdown
    },
    boxStyle() {
      if (this.portalStatus === 'Pending') {
        return 'border-dashed border-1 bg-pureblack-0 border-pureblack-20'
      } else {
        return this.expanded ? 'bg-pureblack-0 border-pureblack-10' : 'bg-pureblack-2 border-pureblack-5'
      }
    },
  },

  async mounted() {
    this.$nextTick(() => {
      this.offsetHeight = `${this.$refs.main?.offsetHeight ?? 0}px`
    })
  },

  methods: {
    toggleAccordion() {
      if (this.portalStatus === 'Pending') return
      this.expanded = !this.expanded
      this.$emit('expanded', this.expanded)
    },
  },
}
